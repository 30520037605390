<template>
  <div class="page">
    <el-row>
      <el-col :span="14">
        <h2>产品</h2>
        <span>如果商品不存在，将自动生成一个新的产品。并沿用当前价格</span>
        <el-table style="width: auto;flex:initial;margin-top: 10px" :data="goodsList" border>
          <el-table-column label="产品" prop="goodsName"></el-table-column>
          <el-table-column label="系统编号" prop="goodsId"></el-table-column>
          <el-table-column label="数量" prop="count"></el-table-column>
          <el-table-column label="单价" prop="unitPrice"></el-table-column>
          <el-table-column label="小结" prop="totalPrice"></el-table-column>
        </el-table>
        <h2>运费</h2>
        <el-form label-width="120px" label-position="left">
          <el-form-item label="运费" prop="freight">
            <el-input v-model="freightObj.freight"></el-input>
          </el-form-item>
          <el-form-item label="总运费" prop="freight">
            <el-input v-model="freightObj.totalFreight"></el-input>
          </el-form-item>
        </el-form>
        <h2>用户</h2>
        <el-form label-width="120px" label-position="left" v-if="!userObj.id">
          <el-form-item label="用户">
            <el-input v-model="userObj.name"></el-input>
          </el-form-item>
          <el-form-item label="Email">
            <el-input v-model="userObj.email"></el-input>
          </el-form-item>
          <el-form-item label="地址1">
            <el-input v-model="userObj.address1"></el-input>
          </el-form-item>
          <el-form-item label="地址2">
            <el-input v-model="userObj.address2"></el-input>
          </el-form-item>
          <el-form-item label="国家">
            <el-input v-model="userObj.national"></el-input>
          </el-form-item>
          <el-form-item label="邮编">
            <el-input v-model="userObj.postcode"></el-input>
          </el-form-item>
        </el-form>
        <span v-else>{{userObj.name}}</span>
        <div class="fucView">
          <el-button type="primary" @click="">创建订单</el-button>
          <el-button type="primary" @click="">创建订单并通知用户</el-button>
          <el-button type="primary" @click="">通知用户</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    name: "inquiryCreateOrder",
    data(){
      return{
        goodsList:[
          {
            goodsName:"苹果",
            goodsId:"123",
            count:1,
            unitPrice:10,
            totalPrice:10
          },
          {
            goodsName:"香蕉",
            goodsId:"123",
            count:1,
            unitPrice:10,
            totalPrice:10
          }
        ],
        freightObj:{
          freight:0,
          totalFreight:0
        },
        userObj:{
          id:"",
          name:"",
          email:"",
          address1:"",
          address2:"",
          national:"",
          postcode:""
        }
      }
    }
  }
</script>

<style scoped>
  .page {
    background-color: #FFF;
    padding: 20px;
    margin: 20px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
</style>